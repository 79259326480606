import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CurrentUserState } from '../../../../../state/CurrentUserState'
import { useOwnershipState } from '../../index.state'
import * as s from '../index.styles'
import { OwnershipsSelector } from '../Permissions/OwnershipsSelector'

const StyledWrapper = styled(s.Wrapper)`
  max-width: 1060px;
  margin: 0 auto;
`

const Content = styled.div`  
  a {
    color: #fff;
  }
  @media (min-width: 1040px) {
    text-align: left;
    width: 100%;
  }
`

const Transfer = () => {
  const { zookeepers, onUpdatePermissions, refetchZookeepers } = useOwnershipState()
  const { currentUser } = CurrentUserState.useContainer()
  const ownerships = useMemo(() => {
    return zookeepers.reduce(
      (acc: IAccountOwnership[], z) => [...acc, ...z.accountOwnerships],
      []
    )
  }, [zookeepers])

  useEffect(() => {
    refetchZookeepers()
  }, [])

  const ownershipMap = useMemo(() => {
    return zookeepers.reduce((acc: any, z) => {
      z.accountOwnerships.map((ownership) => {
        const newOwnership = {
          ...ownership,
          permissions: {
            access: {
              read: ownership.permissions.access.read,
              update: ownership.permissions.access.update,
            },
            activities: {
              read: ownership.permissions.activities.read,
              update: ownership.permissions.activities.update,
            },
            details: {
              read: ownership.permissions.details.read,
              update: ownership.permissions.details.update,
            },
            friends: {
              read: ownership.permissions.friends.read,
              update: ownership.permissions.friends.update,
            },
            privacy: {
              read: ownership.permissions.privacy.read,
              update: ownership.permissions.privacy.update,
            },
            canTakeOwnership: ownership.permissions.canTakeOwnership,
          },
        }

        acc[ownership._id] = newOwnership
        return null
      })
      return acc
    }, {})
  }, [zookeepers])

  const [selectedIds, setSelectedIds] = useState<string[]>(
    ownerships.filter((o) => o.permissions.canTakeOwnership).map((o) => o._id)
  )

  useEffect(() => {
    setSelectedIds(
      ownerships.filter((o) => o.permissions.canTakeOwnership).map((o) => o._id)
    )
  }, [ownerships])

  const onSelectedIds = async (ids: string[]) => {
    if (ids.length === selectedIds.length) return setSelectedIds(ids)
    if (ids.length >= selectedIds.length) {
      // Find which are missing from old
      const added = ids.filter((d) => selectedIds.indexOf(d) < 0)
      await Promise.all(
        added.map(async (id) => {
          return onUpdatePermissions([id], {
            ...ownershipMap[id].permissions,
            canTakeOwnership: true,
          })
        })
      )
    } else {
      const removed = selectedIds.filter((d) => ids.indexOf(d) < 0)
      await Promise.all(
        removed.map(async (id) => {
          return onUpdatePermissions([id], {
            ...ownershipMap[id].permissions,
            canTakeOwnership: false,
          })
        })
      )
    }

    setSelectedIds(ids)
    return null
  }

  return (
    <StyledWrapper>
      <s.Title>Manage Ownership Transfer</s.Title>
      {currentUser.userType === 'teacher' && <Content>
        <p>To transfer all children to a new owner (e.g. a new class teacher), please contact <a href="mailto:support@nightzookeeper.com">support@nightzookeeper.com</a></p>
        <p>
          To transfer an individual child (e.g. if they have left your class),
          visit their settings page.
        </p>
      </Content>}
      <s.Section>
        <s.SectionTitle>Permissions</s.SectionTitle>
        <p>
        Followers can take ownership of an account once the subscription expires. Should you wish to prevent this for individual accounts, you can disable the toggles next to the accounts below.
        </p>
        <p>The previous owner automatically becomes a follower when ownership is transferred.</p>
        <OwnershipsSelector
          dismiss={() => {}}
          selectedOwnershipIds={selectedIds}
          onChange={(ids) => onSelectedIds(ids)}
          noModal
          noTitle
        />
      </s.Section>
    </StyledWrapper>
  )
}

export default Transfer
