import { Button, Close } from 'nzk-react-components'
import React, { useState } from 'react'
import Div100vh from 'react-div-100vh'
import styled from 'styled-components'
import { CurrentUserState } from '../../../../../state/CurrentUserState'

const Overlay = styled(Div100vh)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  padding: 0 40px 0 20px;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #341644;
  box-shadow: 0 -6px 0 #531D75, 0 6px 0 #1C042B, 0 12px 0 rgba(0,0,0,0.4);
  color: #fff;
  border-radius: 20px;
  font-size: 16px;
  padding: 30px 20px 50px 20px;
`

const Content = styled.div`
  padding: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 24px;
`

const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: center;
  > * { margin-right: 20px; }
  > :last-child { margin-right: 0; }
`

const Title = styled.div`
  color: #fff;
  font-family: 'Rammetto One';
  margin-bottom: 20px;
  text-shadow: 0 3px 0 #afafaf, 0 6px 0 rgba(0, 0, 0, 0.4);
  font-size: 30px;
`

const Quit = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
`

interface IProps {
  onBlackAndWhite: () => any,
  onColour: () => any,
  dismiss: () => void
}

const LetterFormat = (props: IProps) => {
  const [loading, setLoading] = useState(false)
  const { onBlackAndWhite, onColour, dismiss } = props
  const { currentUser } = CurrentUserState.useContainer()

  return <Overlay onClick={dismiss}>
  <Wrapper onClick={e => {
    e.preventDefault()
    e.stopPropagation()
  }}>
    <Quit onClick={dismiss}>
      <Button theme='red' size='small' onClick={dismiss} round>
        <Close />
      </Button>
    </Quit>
    <Title>Letter Format</Title>
    <Content>How would you like to print your letters?</Content>
    { loading
      ? <Actions>
        <Button theme='primary' size='small' onClick={() => {
        }}>Loading...</Button>
      </Actions>
      : <Actions>
      <Button theme='primary' size='small' onClick={async () => {
        setLoading(true)
        await onColour()
        setLoading(false)
        dismiss()
      }}>{ currentUser.countryCode === 'US' ? 'Color' : 'Colour' }</Button>
      <Button theme='primary' size='small' onClick={async () => {
        setLoading(true)
        await onBlackAndWhite()
        setLoading(false)
        dismiss()
      }}>
        Black and White
      </Button>
    </Actions> }

  </Wrapper>
  </Overlay>
}

export default LetterFormat
