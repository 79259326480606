import AddNicknameLink from 'components/AddNicknameLink'
import Input from 'components/UI/Input'
import useAmplitude from 'hooks/useAmplitude'
import useConfirmModal from 'hooks/useConfirmModal'
import { Avatar, Button, Checkmark, Close, Pdf, Trash } from 'nzk-react-components'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ModalState } from '../../../../../state/ModalState'
import { useOwnershipState } from '../../index.state'
import useParentLettersPrint from '../../useParentLettersPrint'
import LetterFormat from './LetterFormat'

const Wrapper = styled.div`
  background-color: #341644;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
`

const Header = styled.div`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  font-family: 'Rammetto One';
  > * {
    width: 100%;
  }
  > :last-child {
    margin-left: 20px;
  }
  display: none;
  @media (min-width: 1040px) {
    display: grid;
  }
`

const User = styled.div`
  background-color: #ffffff11;
  padding-top: 20px;
  @media (min-width: 1040px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  margin-bottom: 20px;
`

const UserInfo = styled.div`
  display: flex;
  > :first-child {
    margin-right: 20px;
  }
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 1040px) {
    padding: 20px 0px 20px 20px;
  }
`

const Usernames = styled.div`
  padding-top: 10px;
  > :first-child {
    font-family: 'Rammetto One';
  }
`

const Nickname = styled.div``

const Username = styled.div``

const OwnershipsList = styled.div`
  @media (min-width: 1040px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const Ownership = styled.div`
  padding: 8px 20px;
  font-family: 'Libre Baskerville';
  font-size: 16px;
  display: flex;
  align-items: center;
  @media (min-width: 1040px) {
    padding: 0;
  }
`

const Manager = styled.div`
  ${(props: { status: any }) => props.status === 'PENDING' && css`
    color: #868484;
  `}
  ${(props: { status: any }) => props.status === 'ACCEPTED' && css`
    color: #fff;
  `}
  ${(props: { status: any }) => props.status === 'DECLINED' && css`
    color: #f00;
  `}
`

const InvitedManager = styled.div`
  color: #868484;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 10px;
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const DeleteButton = styled.div`
  margin-left: auto;
`

const AddOwnership = styled.div`
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  max-height: 100px;
  > * {
    margin-right: 15px;
    min-width: 40px;
  }
  > :last-child {
    margin-right: 0;
  }
  ${(props: { isFocused: boolean }) =>
    props.isFocused &&
    css`
      background-color: #fff;
    `}
`

const Ownerships = () => {
  const { zookeepers, onAddAcccountOwnership, onRemoveAccountOwnership, refetchZookeepers } =
    useOwnershipState()
  const { logEvent } = useAmplitude()
  const { displayModal, dismissModal } = ModalState.useContainer()
  const { generateLetters } = useParentLettersPrint()
  const [addingOwner, setAddingOwner] = useState({})
  const { confirm: confirmDelete } = useConfirmModal(
    <>
      <h2>Delete follower</h2>
      <p>Are you sure you would like to delete this follower.</p>
      <p>This person will no longer see the work or progress of this child.</p>
      <p>This cannot be undone.</p>
    </>
  )

  const updateOwner = (zookeeperId, payload) => {
    setAddingOwner((adding: any) => {
      const newAdding = { ...adding }
      newAdding[zookeeperId] = { ...(newAdding[zookeeperId] || {}), ...payload }
      return newAdding
    })
  }

  const removeOwner = (zookeeperId) => {
    setAddingOwner((adding: any) => {
      const newAdding = { ...adding }
      delete newAdding[zookeeperId]
      return newAdding
    })
  }

  const addOwnerAction = async (zookeeperId) => {
    await onAddAcccountOwnership(addingOwner[zookeeperId].email, zookeeperId, {
      details: { read: true, update: false },
      friends: { read: true, update: false },
      privacy: { read: true, update: false },
      access: { read: true, update: false },
      activities: { read: true, update: false },
      canTakeOwnership: true,
    })
    logEvent('Dashboard: Follower Invitation Sent', { from: 'Manage Followers' })
    removeOwner(zookeeperId)
  }

  const onGenerateLetter = async (ownership) => {
    displayModal(<LetterFormat onBlackAndWhite={() => generateLetters([ownership], 'BLACK')} onColour={() => generateLetters([ownership], 'COLOUR')} dismiss={dismissModal} />)
  }

  return (
    <Wrapper>
      <Header>
        <div>Children</div>
        <div>Followers</div>
        <div>Invite Followers</div>
      </Header>
      {[...zookeepers]
      .sort((a, b) => {
        const aField = a.nickname || a.username
        const bField = b.nickname || b.username
        if (aField < bField) return -1
        if (aField > bField) return 1
        return 0
      }).map((zookeeper) => {
        const isAddingOwner =
          addingOwner[zookeeper._id] && addingOwner[zookeeper._id].email !== ''

        return (
          <User key={zookeeper._id}>
            <UserInfo>
              <Avatar user={{ ...zookeeper, type: 'student' }} size="90px" />
              <Usernames>
                <Nickname>{zookeeper.nickname}</Nickname>
                <Username>{zookeeper.username}</Username>
                { !zookeeper.nickname && <AddNicknameLink user={zookeeper} modalDisplay onChanged={() => {
                  refetchZookeepers()
                }} /> }
              </Usernames>
            </UserInfo>
            <OwnershipsList>
              {zookeeper.accountOwnerships.map((accountOwnership) => (
                <Ownership key={accountOwnership._id}>
                  {accountOwnership.user ? (
                    <Manager status={accountOwnership.status}>{accountOwnership.user.name}</Manager>
                  ) : (
                    <InvitedManager>
                      {accountOwnership.email ||
                        <><Button round size='x-small' theme='primary' onClick={() => onGenerateLetter({
                          ...accountOwnership,
                          zookeeper
                        })}>
                          <Pdf />
                        </Button>
                        <div>
                          Student letter ({accountOwnership.code})
                        </div></>
                      }
                    </InvitedManager>
                  )}
                  <DeleteButton>
                    <Button
                      theme="red"
                      size="small"
                      round
                      onClick={() =>
                        confirmDelete(
                          () => {
                            onRemoveAccountOwnership(
                              zookeeper._id,
                              accountOwnership._id
                            )
                          },
                          () => {}
                        )
                      }
                    >
                      <Trash />
                    </Button>
                  </DeleteButton>
                </Ownership>
              ))}
            </OwnershipsList>
            <AddOwnership isFocused={isAddingOwner}>
              <Input
                placeholder="Enter Email"
                value={isAddingOwner ? addingOwner[zookeeper._id].email : ''}
                onChange={(e) =>
                  updateOwner(zookeeper._id, { email: (e.target.value || '').toLocaleLowerCase() })
                }
              />
              {isAddingOwner && (
                <Button
                  theme="confirm"
                  round
                  size="small"
                  onClick={() => addOwnerAction(zookeeper._id)}
                >
                  <Checkmark />
                </Button>
              )}
              {isAddingOwner && (
                <Button
                  theme="red"
                  round
                  size="small"
                  onClick={() => removeOwner(zookeeper._id)}
                >
                  <Close />
                </Button>
              )}
            </AddOwnership>
          </User>
        )
      })}
    </Wrapper>
  )
}

export default Ownerships
