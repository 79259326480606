import { Button, Checkmark, Eye, Transfer } from 'nzk-react-components'
import React from 'react'
import styled, { css } from 'styled-components'
import useNav, { TABS } from '../useNav'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  font-family: 'Rammetto One';
  color: #fff;
  background-color: #341644;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 15px;
  > * {
  }
  > :last-child {
    margin-right: 0px;
  }

  z-index: 10;
  box-shadow: 0 -2px 0 #531d75;
  font-size: 14px;
  @media (min-width: 1040px) {
    position: sticky;
    top: 0;
    font-size: 16px;
  }
`

const Element = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 10px 10px;
  > :first-child {
    margin-bottom: 13px;
  }
  ${(props: { selected: boolean }) =>
    props.selected &&
    css`
      color: #FFED00;
      background-color: #ffffff22;
    `}
`

const Nav = () => {
  const { tab, changeTab } = useNav()
  return (
    <Wrapper>
      <Element
        selected={tab === TABS.MANAGE}
        onClick={() => changeTab(TABS.MANAGE)}
      >
        <Button size="regular" theme="purple" round noSvgStyles>
          <Eye />
        </Button>
        <div>Manage</div>
      </Element>
      <Element
        selected={tab === TABS.PERMISSIONS}
        onClick={() => changeTab(TABS.PERMISSIONS)}
      >
        <Button size="regular" theme="purple" round noSvgStyles>
          <Checkmark />
        </Button>
        <div>Permissions</div>
      </Element>
      <Element
        selected={tab === TABS.TRANSFER}
        onClick={() => changeTab(TABS.TRANSFER)}
      >
        <Button size="regular" theme="purple" round noSvgStyles>
          <Transfer />
        </Button>
        <div>Transfer</div>
      </Element>
    </Wrapper>
  )
}

export default Nav
