import {
  IAccountOwnership,
  IAccountOwnershipPermissions
} from 'components/pages/StudentPage/AccountManagersSection/types.d'
import { Button, Chart, Comment, Settings } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { ReactComponent as EditIcon } from '../../../../../assets/images/icons/edit-icon-button.svg'
import { useOwnershipState } from '../../index.state'
import * as s from '../index.styles'
import OwnershipsSelector from './OwnershipsSelector'
import PermissionsSelector, { Permission } from './PermissionsSelector'

const Content = styled.div`
  @media (min-width: 1040px) {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 1fr 1fr;
    grid-gap: 30px;
  }
`

const SelectedFollowers = styled.div`
  padding: 25px 0;
  background-color: #341644;
  border-radius: 20px;
  > div {
    padding: 15px 50px;
    background-color: rgba(255, 255, 255, 0.05);
    font-family: 'Rammetto One';
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Edit = styled.div`
  height: 45px;
  width: 45px;
  cursor: pointer;
`

const PermissionsBox = styled.div`
  background-color: #341644;
  border-radius: 20px;
  padding: 25px 0;
  > div {
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.05);
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
`

const Permissions = () => {
  const { zookeepers, onUpdatePermissions, refetchZookeepers } = useOwnershipState()
  const ownerships = useMemo(() => {
    return zookeepers.reduce(
      (acc: IAccountOwnership[], z) => [...acc, ...z.accountOwnerships],
      []
    )
  }, [zookeepers])
  const [nbChanges, setNbChanges] = useState(0)

  useEffect(() => {
    refetchZookeepers()
  }, [])

  const hasChanged = useMemo(() => {
    return nbChanges > 0
  }, [nbChanges])

  const [selecting, setSelecting] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  // Auto select all on load
  useEffect(() => {
    setSelectedIds(ownerships.map((o) => o._id))
  }, [ownerships])

  const getPermissionsForIds = (selectedIds) => {
    if (selectedIds.length === 0) {
      return {
        details: { read: true, update: false },
        friends: { read: true, update: false },
        privacy: { read: true, update: false },
        access: { read: true, update: false },
        activities: { read: true, update: false },
        canTakeOwnership: true,
      }
    }
    return ownerships
      .filter((o) => selectedIds.indexOf(o._id) >= 0)
      .reduce(
        (acc: IAccountOwnershipPermissions, ownership) => {
          return {
            details: {
              read: acc.details.read
                ? ownership.permissions.details.read
                : acc.details.read,
              update: acc.details.update
                ? ownership.permissions.details.update
                : acc.details.update,
            },
            friends: {
              read: acc.friends.read
                ? ownership.permissions.friends.read
                : acc.friends.read,
              update: acc.friends.update
                ? ownership.permissions.friends.update
                : acc.friends.update,
            },
            privacy: {
              read: acc.privacy.read
                ? ownership.permissions.privacy.read
                : acc.privacy.read,
              update: acc.privacy.update
                ? ownership.permissions.privacy.update
                : acc.privacy.update,
            },
            access: {
              read: acc.access.read
                ? ownership.permissions.access.read
                : acc.access.read,
              update: acc.access.update
                ? ownership.permissions.access.update
                : acc.access.update,
            },
            activities: {
              read: acc.activities.read
                ? ownership.permissions.activities.read
                : acc.activities.read,
              update: acc.activities.update
                ? ownership.permissions.activities.update
                : acc.activities.update,
            },
            canTakeOwnership: acc.canTakeOwnership
              ? ownership.permissions.canTakeOwnership
              : acc.canTakeOwnership,
          }
        },
        {
          details: { read: true, update: true },
          friends: { read: true, update: true },
          privacy: { read: true, update: true },
          access: { read: true, update: true },
          activities: { read: true, update: true },
          canTakeOwnership: true,
        }
      )
  }

  const permissionsForSelectedGroup =
    useMemo<IAccountOwnershipPermissions>(() => {
      return getPermissionsForIds(selectedIds)
    }, [selectedIds])

  const [permissions, setPermissions] = useState(permissionsForSelectedGroup)

  const onPermissionsChanged = (permissions: IAccountOwnershipPermissions) => {
    setPermissions(permissions)
    setNbChanges((c) => c + 1)
  }

  useEffect(() => {
    setPermissions(getPermissionsForIds(selectedIds))
  }, [selectedIds])

  const onSave = async () => {
    setNbChanges(0)
    await onUpdatePermissions(selectedIds, permissions)
  }

  return (
    <s.Wrapper>
      <s.Title>Followers Permissions</s.Title>
      <Content>
      {selecting && (
        <OwnershipsSelector
          dismiss={() => setSelecting(false)}
          selectedOwnershipIds={selectedIds}
          onChange={(ids) => {
            setNbChanges((c) => c + 1)
            setSelectedIds(ids)
          }}
        />
      )}
      <s.Section>
        <s.SectionTitle>These settings apply to:</s.SectionTitle>
        <SelectedFollowers>
          <div>
            <div>Followers ({selectedIds.length} / {ownerships.length})</div>
            <div>
              <Edit onClick={() => setSelecting(true)}>
                <EditIcon />
              </Edit>
            </div>
          </div>
        </SelectedFollowers>
      </s.Section>
      <s.Section>
        <s.SectionTitle>Permissions</s.SectionTitle>
        <p>
          Choose which child features you would like the selected followers to
          be able to change.
        </p>
        <PermissionsSelector
          permissions={permissions}
          onChange={onPermissionsChanged}
        />
      </s.Section>
      {hasChanged && selectedIds.length > 0 && (
        <s.Section>
          <Actions>
            <Button theme="confirm" size="regular" onClick={onSave}>
              Save
            </Button>
          </Actions>
        </s.Section>
      )}
      <s.Section>
        <s.SectionTitle>These cannot be disabled</s.SectionTitle>
        <PermissionsBox>
          <div>
            <Permission
              icon={<Button height={77} round theme='purple'>
              <Chart />
            </Button>}
              title="View Child Progress"
              description="View all progress screens in the dashboard and on the child's in-game profile"
            />
            <Permission
              icon={<Button height={77} round theme='purple'>
              <Settings />
            </Button>}
              title="View Child Settings"
              description="This includes nickame, username, age, month/year of birth and level of difficulty."
            />
            <Permission
              icon={<Button height={77} round theme='primary'>
              <Comment />
            </Button>}
              title="Comment"
              description="Comment on the child's written work."
            />
          </div>
        </PermissionsBox>
      </s.Section>
      </Content>
    </s.Wrapper>
  )
}

export default Permissions
