import AddNicknameLink from 'components/AddNicknameLink'
import Checkbox from 'components/UI/Checkbox'
import Toggle from 'components/UI/Toggle'
import useAmplitude from 'hooks/useAmplitude'
import { ArrowLeft, Avatar, Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ModalState } from '../../../../../state/ModalState'
import { useOwnershipState } from '../../index.state'
import useParentLettersPrint from '../../useParentLettersPrint'
import * as s from '../index.styles'
import LetterFormat from './LetterFormat'

const Content = styled.div`
  text-align: left;
  a {
    color: inherit;
  }
`

const BackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-20%);
`

const ChildrenSelector = styled.div`
  position: relative;
  background-color: #341644;
  border-radius: 20px;
  max-width: 550px;
  padding: 20px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  p {
    padding: 0 20px;
  }
  @media (min-width: 1040px) {
  }
  > * {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  > :nth-child(2n + 1) {
    background-color: #ffffff11;
  }
`

const Header = styled.div`
  font-family: 'Rammetto One';
  background-color: #ffffff00 !important;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  background-color: #ffffff00 !important;
`

const Row = styled.div`
  padding: 20px 20px;
  background-color: #ffffff05;
`

const Zookeeper = styled.div`
  display: flex;
  > :first-child {
    margin-right: 12px;
  }
  > :nth-child(2) {
    padding-top: 12px;
  }
`

const GenerationDetails = styled.div`
  text-align: center;
  > :first-child {
    font-family: 'Rammetto One';
  }
  > * { margin-bottom: 10px; }
  margin-bottom: 20px;
`

interface IProps {
  dismiss: () => void
}

const ParentLetters = (props: IProps) => {
  const { zookeepers, onAddAcountOwnerships, refetchZookeepers } = useOwnershipState()
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const { displayModal, dismissModal } = ModalState.useContainer()
  const { logEvent } = useAmplitude()
  const { generateLetters, available: JSPDFAvailable } = useParentLettersPrint()
  const toggleUserId = (userId) => {
    setSelectedUserIds((selectedUserIds) => {
      if (selectedUserIds.indexOf(userId) >= 0) {
        return selectedUserIds.filter((id) => id !== userId)
      }
      return [...selectedUserIds, userId]
    })
  }

  useEffect(() => {
    setSelectedUserIds(zookeepers.map((z) => z._id))
  }, [zookeepers])

  const allSelected = useMemo(() => {
    return selectedUserIds.length === zookeepers.length
  }, [selectedUserIds])

  const toggleSelectAll = () => {
    if (!allSelected) setSelectedUserIds(zookeepers.map((z) => z._id))
    else setSelectedUserIds([])
  }

  const submit = async (version) => {  
    const permissions = {
      details: { read: true, update: false },
      friends: { read: true, update: false },
      privacy: { read: true, update: false },
      access: { read: true, update: false },
      activities: { read: true, update: false },
      canTakeOwnership: true,
    }
    const ownerships = await onAddAcountOwnerships(selectedUserIds.map(id => ({
      zookeeperId: id,
      permissions
    })))

    if (selectedUserIds.length === 0) return
    await generateLetters(ownerships, version)
    logEvent('Dashboard: Generate parent letters')
    props.dismiss()
  }

  const onGenerateLetter = () => {
    displayModal(<LetterFormat onBlackAndWhite={() => submit('BLACK')} onColour={() => submit('COLOUR') } dismiss={dismissModal} />)
  }

  return (
    <s.Wrapper>
      <s.Title>
        <BackButton>
          <Button size="small" round theme="primary" onClick={props.dismiss}>
            <ArrowLeft height='100%' width="100%" />
          </Button>
        </BackButton>
        Student Letters
      </s.Title>
      <Content>
        <p>
          Download and print out letters for your students.
        </p>

        <p>Each letter includes:</p>
        <ul>
          <li>The student's username</li>
          <li>A code to allow parents to create their accounts.</li>
        </ul>
      </Content>
      <GenerationDetails>
        <div>Generate Letters For:</div>
        <div>{selectedUserIds.length} children</div>
        { JSPDFAvailable
          ? <Button theme="purple" onClick={onGenerateLetter} size="small">
              Generate letters
            </Button>
          : <Button theme="purple" onClick={() => {}} size='small'>Loading...</Button> }
      </GenerationDetails>
      <ChildrenSelector>
        <Header>
          <div>Select All</div>
          <Toggle selected={allSelected} onChange={toggleSelectAll} />
        </Header>
        {[...zookeepers]
        .sort((a, b) => {
          const aField = a.nickname || a.username
          const bField = b.nickname || b.username
          if (aField < bField) return -1
          if (aField > bField) return 1
          return 0
        }).map((zookeeper) => (
          <Row key={zookeeper._id}>
            <Zookeeper>
              <Avatar user={{ ...zookeeper, type: 'student' }} size="90px" />
              <div>
                <div>{zookeeper.nickname}</div>
                <div>{zookeeper.username}</div>
                { !zookeeper.nickname && <AddNicknameLink user={zookeeper} modalDisplay onChanged={() => {
                  refetchZookeepers()
                }} /> }
              </div>
            </Zookeeper>
            <Checkbox
              selected={selectedUserIds.indexOf(zookeeper._id) >= 0}
              onChange={() => toggleUserId(zookeeper._id)}
            />
          </Row>
        ))}
        <Footer>
          { JSPDFAvailable ? <Button theme="purple" onClick={onGenerateLetter} size="regular">
            Generate letters
          </Button> : <Button theme="purple" onClick={() => {}} size='regular'>Loading...</Button> }
        </Footer>
      </ChildrenSelector>
    </s.Wrapper>
  )
}

export default ParentLetters
