import { useLocation } from '@reach/router'
import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CurrentUserState } from '../../../../../state/CurrentUserState'
import * as s from '../index.styles'
import Ownerships from './Ownerships'
import ParentLetters from './ParentLetters'

const StyledWrapper = styled(s.Wrapper)`
  @media (min-width: 1040px) {
    max-width: 1020px;
  }
`

const Manage = () => {
  const location = useLocation()
  const { currentUser } = CurrentUserState.useContainer()
  const params = new URLSearchParams(location.search)
  const [parentLetters, setParentLetters] = useState(
    params.get('generate-letters') === 'true'
  )

  if (parentLetters)
    return <ParentLetters dismiss={() => setParentLetters(false)} />
  return (
    <StyledWrapper>
      <s.Title>Manage Followers</s.Title>
      <p>
        Followers are adults you trust to observe a child’s work and progress.
        Confirmed and invited followers for each child are shown below. You can
        also send individual email invites here.
      </p>
      {currentUser?.userType === 'teacher' && (
        <>
          <p>
            Alternatively, you can generate pdf letters to print and hand out to students.
          </p>
          <Button
            theme="purple"
            size="small"
            onClick={() => setParentLetters(true)}
          >
            Generate Student Letters
          </Button>
        </>
      )}
      <s.Section>
        <s.SectionTitle>Followers by child</s.SectionTitle>
        <Ownerships />
      </s.Section>
      {currentUser?.userType === 'teacher' && (
        <div style={{ marginTop: '20px' }}>
          <Button
            theme="purple"
            size="small"
            onClick={() => setParentLetters(true)}
          >
            Generate Student Letters
          </Button>
        </div>
      )}
    </StyledWrapper>
  )
}

export default Manage
