import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 46px;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 100px;
  @media (min-width: 1040px) {
    max-width: none;
  }
`

export const Title = styled.div`
  position: relative;
  font-family: 'Rammetto One';
  font-size: 22px;
  text-align: center;
  width: 100%;
  text-shadow: 0 2px 0 #aeaeae, 0 4px 0 rgba(0, 0, 0, 0.4);
`

export const Section = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const SectionTitle = styled.div`
  font-family: 'Rammetto One';
  margin-bottom: 10px;
`
