import { Checkmark } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${(props: { selected: boolean }) => props.selected ? '#8CC63F' : '#ffffff'};
  height: 30px;
  width: 30px;
  border-radius: 8px;
`

interface IProps {
  selected: boolean,
  onChange?: (value: boolean) => void
}

const Checkbox = (props: IProps) => {
  return <Wrapper selected={props.selected} onClick={() => props.onChange && props.onChange(!props.selected)}>
    { props.selected && <Checkmark height='100%' width='100%' /> }
  </Wrapper>
}

Checkbox.defaultProps = {
  onChange: () => {}
}

export default Checkbox
