import { IAccountOwnershipPermissions } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import SelectorInfoModal from 'components/pages/StudentPage/StudentPageFeatures/components/SelectorInfoModal'
import Toggle from 'components/UI/Toggle'
import { Button, Controller, Heart, Padlock, Settings, ZooPoint, Info as InfoIcon } from 'nzk-react-components'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { ModalState } from '../../../../../state/ModalState'

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  border-radius: 20px;
  max-width: 550px;
  padding: 20px 0px;
  p {
    padding: 0 20px;
  }
`

const BigIcon = styled.div`
  position: relative;
`

const HelpButton = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Rammetto One';
  padding: 0 15px;
`

const Permissions = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0 15px;
`

const Info = styled.div``

interface IPermissionProps {
  title: string
  description: string
  icon: any
  selected?: boolean
  helpText?: string
  onChange?: (selected: boolean) => void
}

const PermissionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  > :first-child {
    min-width: 90px;
    margin-right: 10px;
    height: 90px;
    width: 90px;
  }
  > :nth-child(2) {
    font-size: 15px;
    line-height: 17px;
    padding-right: 8px;
    flex: 1;
    > :first-child {
      margin-bottom: 6px;
      font-size: 14px;
      font-family: 'Rammetto One';
    }
  }
`

export const Permission = (props: IPermissionProps) => {
  const { displayModal, dismissModal } = ModalState.useContainer()
  const openInfo = () => {
    if (!props.helpText) return
    displayModal(<SelectorInfoModal title={props.title} text={props.helpText} close={dismissModal} />)
  }

  return (
    <PermissionWrapper>
      <BigIcon>
      { props.icon }
      { props.helpText && <HelpButton><Button theme='primary' size='x-small' round onClick={openInfo}>
        <InfoIcon />
      </Button></HelpButton> }
      </BigIcon>
      <div>
        <div>{props.title}</div>
        <div>{props.description}</div>
      </div>
      {props.onChange && (
        <div>
          <Toggle
            selected={Boolean(props.selected)}
            onChange={props.onChange}
          />
        </div>
      )}
    </PermissionWrapper>
  )
}

Permission.defaultProps = {
  onChange: null,
  selected: false,
  helpText: null
}

interface IProps {
  permissions: IAccountOwnershipPermissions
  onChange: (permissions: IAccountOwnershipPermissions) => void
}

const PermissionsSelector = (props: IProps) => {
  const { permissions } = props

  const updatePermissions = (payload) => {
    props.onChange({ ...permissions, ...payload })
  }

  const allPermissionsSelected = useMemo(() => {
    return (
      permissions.details.update &&
      permissions.friends.update &&
      permissions.privacy.update &&
      permissions.access.update &&
      permissions.activities.update
    )
  }, [permissions])

  const enableAll = () => {
    updatePermissions({
      details: { read: true, update: true },
      friends: { read: true, update: true },
      privacy: { read: true, update: true },
      access: { read: true, update: true },
      activities: { read: true, update: true },
      canTakeOwnership: permissions.canTakeOwnership,
    })
  }

  const disableAll = () => {
    updatePermissions({
      details: { read: true, update: false },
      friends: { read: true, update: false },
      privacy: { read: true, update: false },
      access: { read: true, update: false },
      activities: { read: true, update: false },
      canTakeOwnership: permissions.canTakeOwnership,
    })
  }

  const toggleAllPermissions = useCallback(() => {
    if (allPermissionsSelected) {
      disableAll()
    } else {
      enableAll()
    }
  }, [allPermissionsSelected])

  return (
    <Wrapper>
      <Actions>
        <div>Select All</div>
        <div>
          <Toggle
            selected={allPermissionsSelected}
            onChange={toggleAllPermissions}
          />
        </div>
      </Actions>
      <Permissions>
        <Permission
          icon={<Button height={77} round theme='purple'>
          <Settings />
        </Button>}
          selected={permissions.details.update}
          onChange={(value) =>
            updatePermissions({ details: { read: true, update: value } })
          }
          title="Edit Child Settings"
          description="Change password, age and level of difficulty."
          helpText="If this is disabled, the follower can see the child's settings page but cannot make any changes, i.e. to age, month/year of birth, level of difficulty, nickname or password."
        />
        <Permission
          icon={<Button height={77} round theme='purple'>
          <Heart />
        </Button>}
          selected={permissions.friends.update}
          onChange={(value) =>
            updatePermissions({ friends: { read: true, update: value } })
          }
          title="Manage friends"
          description="View, add and remove friends of the child"
          helpText="If this is disabled, the 'Manage Friends' section will be hidden for followers. Followers will not be able to see a child's friends in their dashboard and will not be able to add or remove friends."
        />
      </Permissions>
      <Info>
        <p>
          Within Child {`>`} Manage Features, which features should followers be
          able to change:
        </p>
      </Info>
      <Permissions>
        <Permission
          icon={<Button height={77} round theme='purple'>
            <Padlock />
          </Button>}
          selected={permissions.privacy.update}
          onChange={(value) =>
            updatePermissions({ privacy: { read: true, update: value } })
          }
          title="Privacy"
          description="Manage features related to privacy."
          helpText="If this is disabled, the follower will not be able to change the 'Privacy' settings within 'Manage Features' for a child. i.e. they will not be able to enable or disable: Friends, Sharing, Leagues or Commenting."
        />
        <Permission
          icon={<Button height={77} round theme='purple'>
          <ZooPoint />
        </Button>}
          selected={permissions.access.update}
          onChange={(value) =>
            updatePermissions({ access: { read: true, update: value } })
          }
          title="Sections"
          description="Manage which sections the child can access (e.g. disable zoo)."
          helpText="If this is disabled, the follower will not be able to change the 'Sections' settings within 'Manage Features' for a child, i.e. they will not be able to enable/disable the zoo, blog or messages features."
        />
        <Permission
          icon={<Button height={77} round theme='purple'>
          <Controller />
        </Button>}
          selected={permissions.activities.update}
          onChange={(value) =>
            updatePermissions({ activities: { read: true, update: value } })
          }
          title="Activity Types"
          description="Manage which activity types the child can do (e.g. disable games)."
          helpText="If this is disabled, the follower will not be able to change the 'Activity Types' settings within 'Manage Features' for a child, i.e. they will not be able to enable/disable the projects, lessons, games, writing or upload features."
        />
      </Permissions>
    </Wrapper>
  )
}

export default PermissionsSelector
