import React from 'react'
import styled from 'styled-components'
import Nav from './components/Nav'
import { OwnershipPageStateProvider } from './index.state'
import Manage from './pages/Manage'
import Permissions from './pages/Permissions'
import Transfer from './pages/Transfer'
import useNav, { TABS } from './useNav'

const Content = styled.div`
  color: #fff;
`

const OwnershipsPage = () => {
  const { tab } = useNav()
  return (
    <OwnershipPageStateProvider>
      <div>
        <Nav />
        <Content>
          {tab === TABS.MANAGE && <Manage />}
          {tab === TABS.PERMISSIONS && <Permissions />}
          {tab === TABS.TRANSFER && <Transfer />}
        </Content>
      </div>
    </OwnershipPageStateProvider>
  )
}

export default OwnershipsPage
