import { navigate, useLocation } from '@reach/router'
import { useEffect, useState } from 'react'

export type ITab = 'MANAGE' | 'PERMISSIONS' | 'TRANSFER'

export const TABS = {
  MANAGE: 'MANAGE' as ITab,
  PERMISSIONS: 'PERMISSIONS' as ITab,
  TRANSFER: 'TRANSFER' as ITab,
}

const useNav = () => {
  const location = useLocation()
  const [tab, setTab] = useState(TABS.MANAGE)

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const tab = urlParams.get('tab')
    setTab((tab as ITab) || TABS.MANAGE)
  }, [location])

  const changeTab = (tab: ITab) => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.set('tab', tab)
    navigate(`${location.pathname}?${urlParams.toString()}`)
  }
  return { tab, changeTab }
}

export default useNav
