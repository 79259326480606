import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import config from '../../../config';

export default () => {
  const JSPDFref = useRef<any>()
  const [available, setAvailable] = useState(false)
  const JSPDF = JSPDFref.current

  useEffect(() => {
    const init = async () => {
      const { default: JSPDF } = await import('jspdf')
      JSPDFref.current = JSPDF
      setAvailable(true)
    }
    init()
  }, [])


  const loadImage = async (src: string = '/parent-letters/black.jpg'): Promise<HTMLImageElement> => {
    return new Promise((resolve) => {
      const image = new Image()
      image.src = src
      image.onload = () => {
        resolve(image)
      }
    })
  }

  const generateQRCode = async (src: string): Promise<string> => {
    return new Promise((resolve) => {
      QRCode.toDataURL(src, { type: 'image/png', color: { light: '#0000', dark: '#000' } })
      .then(url => {
        resolve(url)
      })
      .catch(err => {
        console.error(err)
      })
    })

  }


  const generateLetters = async (ownerships, version: 'BLACK' | 'COLOUR' = 'BLACK') => {
    if (!JSPDF) return
    const doc = new JSPDF();
    const image = await loadImage(version === 'COLOUR'
      ? '/parent-letters/colour.jpg'
      : '/parent-letters/black.jpg')

    
    doc.setFont('Libre Baskerville')
    await ownerships.reduce(async (acc, ownership: any, i) => {
      await acc
      doc.addImage(image, 'JPEG', 0, 0, 210, 297) 
      doc.text(ownership.zookeeper.nickname || '', 107, 240)
      doc.text(ownership.zookeeper.username, 107, 249)
      doc.text(ownership.code, 107, 267)
      const qrcodeUrl = await generateQRCode(`${config.url}/addfollower?code=${ownership.code}`)
      const qrcodeImage = await loadImage(qrcodeUrl)
      doc.addImage(qrcodeImage, 'PNG', 138, 55, 47, 47)
      if (i < ownerships.length - 1) {
        doc.addPage()
      }
      return null
    }, Promise.resolve())
    let title = 'parent-letters.pdf'
    if (ownerships.length === 1) {
      title = `parent-letter-${ownerships[0].zookeeper.username}.pdf`
    }
    doc.save(title)
  }

  return {
    available,
    generateLetters
  }
} 

