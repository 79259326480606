import { useApolloClient } from '@apollo/client'
import ADD_ACCOUNT_OWNERSHIP from './graphql/addOwnership.graphql'
import GET_CHILDREN_OWNERSHIPS from './graphql/getChildrenOwnerships.graphql'

const useData = () => {
  const client = useApolloClient()
  const fetch = async () => {
    const { data } = await client.query({
      query: GET_CHILDREN_OWNERSHIPS,
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    })
    return data.me.children
  }

  const addAccoutOwnership = async (email) => {
    const { data } = await client.mutate({
      mutation: ADD_ACCOUNT_OWNERSHIP,
      variables: {
        email,
      },
    })
    return data.addAccoutOwnership
  }

  return { fetch, addAccoutOwnership }
}

export default useData
