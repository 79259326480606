import { Button, Close } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #2F163B;
  box-shadow: 0 -4px 0 #4B1B68, 0 4px 0 #1B0525, 0 8px 0 rgba(0,0,0,0.4);
  border-radius: 16px;
  padding: 20px;
  color: #fff;
  max-width: 450px;
  width: 100%;
  > :first-child {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
  }
`

const Title = styled.div`
  font-size: 21px;
  font-family: 'Rammetto One';
  margin-bottom: 15px;
`

const Content = styled.div`
`

interface IProps {
  title: string,
  text: string,
  close: () => void
}

const SelectorInfoModal = (props: IProps) => {
  return <Overlay onClick={props.close}>
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Button backgroundColor='#f00' size='small' round onClick={props.close}>
        <Close />
      </Button>
      <Title>{props.title}</Title>
      <Content>
        {props.text}
      </Content>
    </Wrapper>
  </Overlay>
}

export default SelectorInfoModal
