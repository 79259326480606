import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { IAccountOwnershipPermissions } from '../StudentPage/AccountManagersSection/types.d'
import useAccountManagerData from '../StudentPage/AccountManagersSection/useData'
import { IStudentType } from '../StudentPage/index.state'
import useData from './useData'

const OwnershipPageState = () => {
  const [zookeepers, setZookeepers] = useState<IStudentType[]>([])
  const { fetch } = useData()
  const { addAccountOwnership, removeAccountOwnership, updatePermissions } =
    useAccountManagerData()

  useEffect(() => {
    const init = async () => {
      const zookeepers = await fetch()
      setZookeepers(zookeepers)
    }
    init()
  }, [])

  const refetchZookeepers = async () => {
    const zookeepers = await fetch()
    setZookeepers(zookeepers)
  }

  const onAddAcccountOwnership = async (email, zookeeperId, permissions) => {
    const ownership = await addAccountOwnership(email, zookeeperId, permissions)
    setZookeepers((zookeepers) => {
      return [...zookeepers].map((zookeeper) => {
        if (zookeeper._id === zookeeperId) {
          return {
            ...zookeeper,
            accountOwnerships: [...zookeeper.accountOwnerships, ownership],
          }
        }
        return zookeeper
      })
    })
    return ownership
  }

  const onAddAcountOwnerships = async (inputs: { email?, zookeeperId, permissions }[]) => {
    const ownerships = await Promise.all(inputs.map(async ({ email, zookeeperId, permissions }) => {
      return addAccountOwnership(email, zookeeperId, permissions)
    }))
    setZookeepers((zookeepers) => {
      return [...zookeepers].map((zookeeper) => {
        const ownershipFiltered = ownerships.filter((o: any) => o.zookeeper._id === zookeeper._id)
        const ownership = ownershipFiltered && ownershipFiltered[0]
        if (!ownership) return zookeeper
        return {
          ...zookeeper,
          accountOwnerships: [...zookeeper.accountOwnerships, ownership],
        }
      })
    })
    return ownerships
  }

  const onRemoveAccountOwnership = async (zookeeperId, ownershipId) => {
    await removeAccountOwnership(ownershipId)
    setZookeepers((zookeepers) => {
      return [...zookeepers].map((zookeeper) => {
        if (zookeeper._id === zookeeperId) {
          return {
            ...zookeeper,
            accountOwnerships: [...zookeeper.accountOwnerships].filter(
              (ownership) => ownership._id !== ownershipId
            ),
          }
        }
        return zookeeper
      })
    })
  }

  const onUpdatePermissions = async (
    ownershipIds: string[],
    permissions: IAccountOwnershipPermissions
  ) => {
    await Promise.all(
      ownershipIds.map(async (ownershipId) => {
        return updatePermissions(ownershipId, permissions)
      })
    )
    return true
  }

  return {
    zookeepers,
    onAddAcccountOwnership,
    onAddAcountOwnerships,
    onRemoveAccountOwnership,
    onUpdatePermissions,
    refetchZookeepers
  }
}

const OwnershipPageStateContainer = createContainer(OwnershipPageState)

export const OwnershipPageStateProvider = OwnershipPageStateContainer.Provider

export const useOwnershipState = OwnershipPageStateContainer.useContainer
