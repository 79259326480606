import AddNicknameLink from 'components/AddNicknameLink'
import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import { Avatar, Button, Close } from 'nzk-react-components'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import Toggle from '../../../../UI/Toggle'
import { useOwnershipState } from '../../index.state'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: auto;
`

const OwnershipsSelectorModalWrapper = styled.div`
  position: absolute;
  top: 30px;
  padding-bottom: 30px;
`

const Wrapper = styled.div`
  position: relative;
  top: 50px;
  margin-bottom: 50px;
  background-color: #341644;
  border-radius: 20px;
  max-width: 550px;
  padding: 20px 0px;
  p {
    padding: 0 20px;
  }
  @media (min-width: 1040px) {
    max-width: 100%;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Rammetto One';
  padding: 0 15px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
`

const Title = styled.div`
  font-family: 'Rammetto One';
  text-align: center;
`

const Users = styled.div`
  @media (min-width: 1040px) {
    > * {
      background-color: #ffffff11;
      margin-bottom: 20px;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`

const Zookeeper = styled.div`
  @media (min-width: 1040px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`

const User = styled.div`
  display: flex;
  padding: 10px 20px;
  > :first-child {
    margin-right: 20px;
    margin-bottom: 15px;
  }
  > :last-child {
    padding-top: 12px;
    > :first-child {
      font-weight: bold;
      font-family: 'Rammetto One';
    }
  }
  background-color: rgba(255, 255, 255, 0.07);
  @media (min-width: 1040px) {
    background-color: rgba(255, 255, 255, 0);
  }
`

const Parents = styled.div`
  padding: 0 5px 0 20px;

  background-color: rgba(255, 255, 255, 0.05);
  @media (min-width: 1040px) {
    padding: 0 5px;
    background-color: rgba(255, 255, 255, 0);
  }
`

const Parent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  font-family: 'Libre Baskerville';
  padding-right: 10px;
  font-size: 15px;
  > :first-child {
    margin-right: 20px;
  }
  ${(props: { status: string }) => {
    if (props.status === 'PENDING')
      return css`
        color: #868484;
      `
    if (props.status === 'ACCEPTED') return css``
    if (props.status === 'DECLINED')
      return css`
        color: #de1323;
      `
    return null
  }}
`

const Invited = styled.div`
  font-size: 13px;
`

interface IProps {
  dismiss: () => void
  selectedOwnershipIds: any[]
  onChange: (selectedOwnershipsIds: string[]) => void
  noModal?: boolean
  noTitle?: boolean
}

export const OwnershipsSelector = (props: IProps) => {
  const { zookeepers, refetchZookeepers } = useOwnershipState()
  const initRef = useRef(false)

  const ownerships = useMemo(() => {
    return zookeepers.reduce((acc: IAccountOwnership[], zookeeper) => {
      return [...acc, ...zookeeper.accountOwnerships]
    }, [])
  }, [zookeepers])

  const selectedOwnershipIds = props.selectedOwnershipIds

  const allSelected = useMemo(() => {
    return selectedOwnershipIds.length === ownerships.length
  }, [selectedOwnershipIds, ownerships])

  const selectAll = () => {
    props.onChange(ownerships.map((o) => o._id))
  }

  const unSelectAll = () => {
    props.onChange([])
  }

  const toggleAll = useCallback(() => {
    if (allSelected) unSelectAll()
    else selectAll()
  }, [allSelected])

  useEffect(() => {
    if (initRef.current) {
      props.onChange(selectedOwnershipIds)
    }
  }, [selectedOwnershipIds])

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      {!props.noModal && (
        <CloseButton onClick={props.dismiss}>
          <Button theme="red" size="small" round>
            <Close />
          </Button>
        </CloseButton>
      )}
      {!props.noTitle && <Title>Select Followers</Title>}
      {!props.noTitle && <p>Choose which followers these settings apply to.</p>}
        <Header>
          <div>Select All</div>
          <div>
            <Toggle selected={allSelected} onChange={toggleAll} />
          </div>
        </Header>
      <Users>
        {[...zookeepers]
        .sort((a, b) => {
          const aField = a.nickname || a.username
          const bField = b.nickname || b.username
          if (aField < bField) return -1
          if (aField > bField) return 1
          return 0
        }).map((z) => (
          <Zookeeper key={z._id}>
            <User>
              <Avatar user={z} size="80px" />
              <div>
                <div>{z.nickname}</div>
                <div>{z.username}</div>
                { !z.nickname && <AddNicknameLink user={z} onChanged={() => {
                  refetchZookeepers()
                }} /> }
              </div>
            </User>
            <Parents>
              {z.accountOwnerships.map((a) => (
                <Parent key={a._id} status={a.status}>
                  {a.user ? (
                    <div>{a.user.name}</div>
                  ) : (
                    <div>
                      <div>{a.email || `Code: ${a.code}`}</div>
                      <Invited>(Invited)</Invited>
                    </div>
                  )}
                  <Toggle
                    selected={selectedOwnershipIds.indexOf(a._id) >= 0}
                    onChange={() => {
                      initRef.current = true
                      const ids = [...selectedOwnershipIds]
                      if (selectedOwnershipIds.indexOf(a._id) >= 0) {
                        props.onChange(ids.filter((id) => id !== a._id))
                        return
                      }
                      props.onChange([...ids, a._id])
                    }}
                  />
                </Parent>
              ))}
            </Parents>
          </Zookeeper>
        ))}
      </Users>
    </Wrapper>
  )
}

OwnershipsSelector.defaultProps = {
  noModal: false,
  noTitle: false,
}

const OwnershipsSelectorModal = (props: IProps) => {
  return (
    <Overlay onClick={props.dismiss}>
      <OwnershipsSelectorModalWrapper>
        <OwnershipsSelector {...props} />
      </OwnershipsSelectorModalWrapper>
    </Overlay>
  )
}

OwnershipsSelectorModal.defaultProps = {
  noModal: false,
  noTitle: false,
}

export default OwnershipsSelectorModal
