import React from 'react'
import OwnershipsPage from '../components/pages/OwnershipsPage'
import Layout from '../layouts/layout'

const OwnershipsPageWwapper = () => {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <OwnershipsPage />
    </Layout>
  )
}

export default OwnershipsPageWwapper
